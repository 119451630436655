import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const DataExtractionPage = props => {
    const { t } = useTranslation();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="data-extraction-page:pageTitle">Data Extraction</Trans>}
            subtitle={<Trans i18nKey="data-extraction-page:pageSubtitle">Transform your workflow: unleash efficiency
                                                                         with
                                                                         AIDA's advanced document and email
                                                                         management</Trans>}
            cover="features"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="data-extraction-page:pageDescription">
                <p>AIDA is the ultimate solution for <strong>streamlining your document and email management</strong>,
                   revolutionizing
                   the way you work.</p>
                <p>Our advanced system not only <strong>automates processes</strong> related to archiving, searching,
                   and extracting data from documents, but it also extends to <strong>email management</strong>.<br />
                   AIDA converts your <strong>emails into documents</strong>, further simplifying the organization of
                   your data and ensuring significant time savings.</p>
                <p>With powerful features like <strong>automatic document renaming</strong> and hierarchical folder
                   creation,
                    <strong>intelligent
                            and flexible data extraction</strong>, barcode and QR code recognition, and <strong>automated
                                                                                                                digital
                                                                                                                mailroom</strong>
                   capabilities, AIDA ensures an organized and easily accessible document archive.</p>
            </Trans>
        </div>
        <div className={classes.FeaturesContainer}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-01-automatic-archive.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <a id="automaticArchive" className="anchor" href="#automaticArchive">.</a>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Automatic document archive based on fields</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature1">
                                <p>AIDA <strong>automatically renames your documents</strong> and <strong>creates
                                                                                                          hierarchical
                                                                                                          folder
                                                                                                          trees</strong>,
                                   based on your rules.</p>
                                <p>You can use the values of the fields from the documents to compose the file name
                                   useful for archiving the documents according to your preferences, for each of
                                   the <Link to="/platform/integrations">possible integrations</Link>.</p>
                                <p>Since the extraction of values is simple and immediate, so is having an orderly and
                                   accessible <Link to="/platform/archive-document-management">document archive</Link>.
                                </p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Smart and flexible field extraction</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature2">
                                <p>Fields extraction in AIDA is already trained in 1 click/1 document, but the <strong>smart
                                                                                                                       properties</strong> are
                                   even more powerful.</p>
                                <p>These allow extraction <strong>without any learning</strong> for many formats such as
                                   IBANs, dates, email addresses, company names...</p>
                                <p>Effortless, fully automated extraction for large and small volumes is thus
                                   possible <strong>regardless of the type of document chosen</strong>.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-02-smart-fields-extraction.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-03-barcode-qrcode.jpg"
                            width={901}
                            height={666}
                            alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <a id="barcodes-qrcodes" className="anchor" href="#barcodes-qrcodes">.</a>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Barcode and qrcode reader</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature3">
                                <p>AIDA recognizes and extracts values from documents for
                                   many <strong>Barcode</strong> formats (EAN-8, EAN-13, EAN-14, UPC-A, JAN, ISBN-10,
                                   ISBN-13, ISSN, Code 39, Code 128, PZN) and <strong>QRCode</strong>.</p>
                                <p>Extraction is automatic and requires no prior training.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <a id="split" className="anchor" href="#split">.</a>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Automatic split of multiple documents</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature4">
                                <p>The <strong>automatic page separation</strong> divides multi-document files, such as
                                   those scanned in bundles from a scanner's automatic feeder, into individual
                                   documents.</p>
                                <p>The separation is based on intelligent document recognition, whereby it automatically
                                   adjusts and works independently of the number of pages of each individual
                                   document.</p>
                                <p>There are no limits: many or few pages, scan your bundle or upload your file and AIDA
                                   will extract the individual documents for you.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-04-automatic-document-split.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-05-table-data-extraction.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <a id="table" className="anchor" href="#table">.</a>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Specialized engine for table data extraction</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature5">
                                <p>Extracting <strong>fields from a table</strong> is simple and requires nothing more
                                   than a click on the first row.</p>
                                <p>AIDA will locate subsequent lines, even across multiple pages, and provide you with
                                   the values of any properties you have configured for extraction.</p>
                                <p>Thanks to the lookup, <Link
                                    to="/platform/relations-knowledge-graph">relationships</Link> and cross-checking,
                                   the validation of each single
                                   line against the document totals is automatic and transparent.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>ICR - Intelligent Character Recognition</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature6">
                                <p>Powered by advanced AI algorithms, AIDA's ICR accurately interprets <strong>handwritten
                                                                                                               or
                                                                                                               printed
                                                                                                               characters</strong> from
                                   scanned documents.
                                    <br />ICR engine has undergone rigorous production testing, delivering impressive
                                   reliability results that rival <strong>human-like accuracy</strong>.</p>
                                <p>Additionally, ICR enhances data <strong>accessibility and searchability</strong>,
                                   enabling users to quickly locate and utilize relevant information from their document
                                   archives.</p>
                                <p>Automate form processing, invoice management, digitize handwritten notes, enhance
                                   document indexing, and ensure compliance with AIDA's ICR.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-06-icr-intelligent-character-recognition.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}><StaticImage
                        alt="AIDA"
                        width={901}
                        height={666}
                        src="../../images/data-extraction/aida-data-extraction-07-lookup-anomalies.jpg"
                    />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <a id="lookup" href="#lookup" className="anchor">.</a>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Database lookup and anomaly detection</Trans></h2>
                            <Trans i18nKey="feature7">
                                <p>For even greater control and precision over the extracted data, AIDA offers
                                   you <strong>anomaly detection</strong> and <strong>Lookup</strong>.</p>
                                <p>With the anomaly detection, AIDA automatically analyzes, in the background, the trend
                                   over time of all the extracted fields and can therefore highlight any inconsistencies
                                   in the data or inaccuracies in OCR reading.</p>
                                <p>The Lookup, on the other hand, enables auto-completion, normalization and validation
                                   of the extracted values, based on data you have already validated which can also be
                                   Excel tables, or other documents that precede, chronologically, the document under
                                   analysis.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <a id="history" className="anchor" href="#history">.</a>
                            <div className={classes.SmallTitle}>{t('data-extraction-page:pageTitle')}</div>
                            <h2><Trans>Stay in control of your documents</Trans></h2>
                            <Trans i18nKey="data-extraction-page:feature8">
                                <p>AIDA supports notifications via <strong>email</strong> and <strong>mobile
                                                                                                      push</strong>,
                                   which are fully configurable.</p>
                                <p>While working silently in the background, it only alerts you if some event needs your
                                   attention, such as a field read with low confidence.</p>
                                <p>The <Link to="/platform/archive-document-management#combinedHistory">history
                                                                                                        panel</Link> shows
                                   all aspects of the life of the document, where it's coming
                                   from, where it has gone.</p>
                                <p>AIDA works for you, not vice versa.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/data-extraction/aida-data-extraction-08-stay-in-control-v2.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default DataExtractionPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "data-extraction-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'data-extraction-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Analysis - Data Extraction';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
